import { generatePath as routerGeneratePath } from "react-router-dom";

import { routes, RoutesPaths } from "@/routes/routes";
import { RouteName } from "@/routes/routes.types";

/**
 * Array of routeNames from routes object
 */
export const routeNames = Object.keys(routes) as RouteName[];

/**
 * Type that extracts parameters from react-router string path
 */
type ExtractRouteParams<T extends string> = T extends `${string}:${infer Param}/${infer Rest}`
    ? { [K in Param | keyof ExtractRouteParams<`/${Rest}`>]: string }
    : T extends `${string}:${infer Param}`
      ? { [K in Param]: string }
      : Record<string, never>;

export const generatePath = <T extends RoutesPaths>(path: T, args: ExtractRouteParams<T>) => {
    return routerGeneratePath(path, args);
};
