import { z } from "zod";

const requiredMessage = "Field is required";

const loginPageFormSchema = z.object({
    username: z.string().min(1, { message: requiredMessage }),
    password: z.string().min(1, { message: requiredMessage }),
});

type LoginPageFormSchema = z.infer<typeof loginPageFormSchema>;

export { loginPageFormSchema };
export type { LoginPageFormSchema };
