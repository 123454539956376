import { PageHeader } from "@/components/Layout/Page/Header/PageHeader";
import { Page as PageComponent } from "@/components/Layout/Page/Page";
import { PageSectionHeader } from "@/components/Layout/Page/Section/Header/PageSectionHeader";
import { PageSection } from "@/components/Layout/Page/Section/PageSection";

export const Page = Object.assign(PageComponent, {
    Header: PageHeader,
    Section: Object.assign(PageSection, {
        Header: PageSectionHeader,
    }),
});
