import { FormControl } from "@datamole/wds-component-form-control";
import { Input } from "@datamole/wds-component-input";
import { Tooltip } from "@datamole/wds-component-tooltip";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { ErrorFallback } from "@/components/ErrorFallback";
import { AppRoutes } from "@/routes/AppRoutes";
import { store } from "@/store";

import "@datamole/wds-css-normalize";
import "@datamole/wds-css-theme-lely/lely.theme.css";
import "@/styles/global.css";

/**
 * Component representing the main application
 *
 * @returns rendered application
 */
const App = () => {
    return (
        <Provider store={store}>
            <ErrorBoundary fallback={<ErrorFallback />}>
                <Tooltip.Provider>
                    {/* FIXME: Import the components to preload their CSS styles. */}
                    <div style={{ display: "none" }}>
                        <Input />
                        <FormControl />
                    </div>
                    <BrowserRouter>
                        <AppRoutes />
                    </BrowserRouter>
                </Tooltip.Provider>
            </ErrorBoundary>
        </Provider>
    );
};

export { App };
