import { Button } from "@datamole/wds-component-button";
import { Input } from "@datamole/wds-component-input";
import { Navbar as WdsNavbar } from "@datamole/wds-component-navbar";
import { faCog, faBell, faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import styles from "@/components/Layout/Navbar/Navbar.module.css";
import { UserMenu } from "@/components/Layout/Navbar/actions/UserMenu";

type Props = {
    className: string;
};

const Navbar = ({ className }: Props) => {
    const searchbarRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    // Temporary way to display Customer Detail - hit enter in search field
    // TODO: Replace with proper search logic eventually
    useEffect(() => {
        const listener = (event: KeyboardEvent) => {
            const placeholderCustomer = "5164481-0411";

            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                navigate(`/customer-detail/${placeholderCustomer}`);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [navigate]);

    return (
        <WdsNavbar className={clsx(className, styles.navbar)}>
            <WdsNavbar.Content>
                <Input
                    name="search"
                    placeholder="Find a customer..."
                    className={styles.search_bar}
                    ref={searchbarRef}
                />
            </WdsNavbar.Content>
            <WdsNavbar.Actions>
                <Button className={styles.action_button}>
                    <FontAwesomeIcon icon={faBell} />
                </Button>

                <WdsNavbar.Divider />

                <Button className={styles.action_button}>
                    <FontAwesomeIcon icon={faCog} />
                </Button>

                <Button className={styles.action_button}>
                    <Button.Icon>
                        <FontAwesomeIcon icon={faExternalLink} />
                    </Button.Icon>
                    <Button.Content>LSSA</Button.Content>
                </Button>

                <UserMenu />
            </WdsNavbar.Actions>
        </WdsNavbar>
    );
};

export { Navbar as Navbar };
