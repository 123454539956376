import { Navigate, Outlet } from "react-router-dom";

import { useAppSelector } from "@/store";

const ProtectedRoute = () => {
    const accessToken = useAppSelector((state) => state.auth.accessToken);

    if (accessToken === null) {
        return <Navigate to="/login" />;
    }

    return <Outlet />;
};

export { ProtectedRoute };
