import { LoginRequestDtoSchema, LoginResponseDtoSchema } from "@lely-it-observability/api-schema";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { config } from "@/config";
import { login } from "@/store/slices/auth.slice";

const baseUrl = `${config.API_BASE_URL}auth`;
const baseQuery = fetchBaseQuery({ baseUrl });

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery,
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponseDtoSchema, LoginRequestDtoSchema>({
            query: (data) => ({
                url: "/login",
                method: "POST",
                body: data,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(login({ accessToken: data.accessToken }));
                } catch {
                    // Errors are displayed in the form on the login page
                }
            },
        }),
    }),
});

export const { useLoginMutation } = authApi;
