import clsx from "clsx";
import { HTMLAttributes, ReactNode } from "react";

import styles from "@/components/Layout/Page/Page.module.css";

type Props = HTMLAttributes<HTMLElement> & {
    children: ReactNode;
};

export const Page = (props: Props) => {
    const { children, className, ...rest } = props;

    return (
        <div className={clsx(styles.page, className)} {...rest}>
            {children}
        </div>
    );
};
