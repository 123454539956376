import styles from "@/pages/LoginPage/LoginPageTitle.module.css";

const LoginPageTitle = () => {
    return (
        <div className={styles.title}>
            <div className={styles.title_welcome_message}>Welcome to</div>
            <div className={styles.title_app_name}>IT Observability</div>
        </div>
    );
};

export { LoginPageTitle };
