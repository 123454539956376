import { Route } from "@/routes/routes.types";

/**
 * Create routes object where each route has its path and params
 */
export const routes = {
    dashboard: {
        path: "/dashboard",
    } as const,

    horizonVersion: {
        path: "/software/horizon-version",
    } as const,
    unsupportedVersions: {
        path: "/software/unsupported-versions",
    } as const,
    databaseHealth: {
        path: "/software/database-health",
    } as const,

    hwStatus: {
        path: "/hardware/status",
    } as const,
    numberOfRobots: {
        path: "/hardware/number-of-robots",
    } as const,

    customerDetail: {
        path: "/customer-detail/:customerNumber", // customerId is just current assumption
    } as const,
} satisfies Record<string, Route>;

/**
 * Define Routes type representing all the defined routes
 */
export type Routes = typeof routes;
export type RoutesPaths = Pick<(typeof routes)[keyof typeof routes], "path">["path"];
