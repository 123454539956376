import { Sidebar } from "@datamole/wds-component-sidebar";

import styles from "@/components/Layout/Sidebar/Header/Header.module.css";
import logo from "@/components/Layout/Sidebar/Header/assets/logo-lely.png";

export const Header = () => (
    <Sidebar.Header className={styles.header}>
        <img className={styles.logo} alt="Lely logo" src={logo} />
        <div className={styles.logo_text}>IT Observability</div>
    </Sidebar.Header>
);
