import { z } from "zod";

const stringSchema = z.string({ required_error: "Not defined" });

const configSchema = z.object({
    MODE: z.enum(["production", "acceptance", "test", "development"]),
    API_BASE_URL: stringSchema.url().refine((val) => val.at(-1) === "/", {
        message: "url should end with the character '/'",
    }),
});

let config: z.infer<typeof configSchema>;
const result = configSchema.safeParse({
    MODE: import.meta.env.MODE,
    API_BASE_URL: import.meta.env.VITE_API_BASE_URL,
});

class EnvironmentVariablesError extends Error {
    name = "EnvironmentVariablesError";

    constructor(errors: Record<string, string[]>) {
        const message = Object.entries(errors)
            .map(([key, errors]) => `${key}: ${errors.join(", ")}`)
            .join("\n");

        super(`\n\nErrors while validating environment variables:\n\n${message}\n\n`);
    }
}

if (result.success) {
    config = result.data;
} else {
    const errors = result.error.flatten();
    throw new EnvironmentVariablesError(errors.fieldErrors);
}

export { config };
