import { Link } from "react-router-dom";

export const PageNotFoundPage = () => {
    // TODO: Remove dashboard hardlink
    return (
        <div>
            <h1>Not found</h1>
            <p>
                Page you are looking for is not here. <Link to={"/dashboard"}>Go back to dashboard?</Link>
            </p>
        </div>
    );
};
