const STORAGE_KEY_PREFIX = "liobs::";

export const createStorageKey = <const T extends string>(key: T) => `${STORAGE_KEY_PREFIX}${key}` as const;

export const STORAGE_KEY = {
    ACCESS_TOKEN: createStorageKey("access-token"),
} as const;

export const setLocalStorageItem = (key: string, value: string): void => {
    try {
        localStorage.setItem(key, value);
    } catch (error) {
        console.error(`Error setting ${key} in localStorage`, error);
    }
};

export const getLocalStorageItem = (key: string): string | null => {
    let item: string | null = null;

    try {
        item = localStorage.getItem(key);
    } catch (error) {
        console.error(`Error getting ${key} from localStorage`, error);
    }

    return item;
};

export const removeLocalStorageItem = (key: string): void => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        console.error(`Error removing ${key} from localStorage`, error);
    }
};
