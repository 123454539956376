import { Button } from "@datamole/wds-component-button";
import { Form } from "@datamole/wds-component-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import styles from "@/pages/LoginPage/LoginPageForm.module.css";
import { LoginPageFormError } from "@/pages/LoginPage/LoginPageFormError";
import { LoginPageFormField } from "@/pages/LoginPage/LoginPageFormField";
import { LoginPageFormSchema, loginPageFormSchema } from "@/pages/LoginPage/login-page-from.schema";
import { useLoginMutation } from "@/store/services/auth.service";

const LoginPageForm = () => {
    const [login] = useLoginMutation();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const formMethods = useForm<LoginPageFormSchema>({
        resolver: zodResolver(loginPageFormSchema),
        mode: "onBlur",
        reValidateMode: "onChange",
    });

    const handleSubmit = formMethods.handleSubmit(async (data) => {
        try {
            await login(data).unwrap();
            setErrorMessage(null);
            navigate("/");
        } catch (error) {
            const errorSchema = z.object({
                data: z.object({
                    message: z.string(),
                }),
            });

            const errorData = errorSchema.safeParse(error);
            if (!errorData.success) {
                setErrorMessage("An error occurred while logging in.");
                return;
            }

            setErrorMessage(errorData.data.data.message);
        }
    });

    return (
        <FormProvider {...formMethods}>
            <Form className={styles.form} onSubmit={handleSubmit}>
                {errorMessage && <LoginPageFormError message={errorMessage} />}

                <LoginPageFormField name={"username"} type={"text"} label={"Email or Username"} />
                <LoginPageFormField name={"password"} type={"password"} label={"Password"} />

                <Form.Actions>
                    <Form.Actions.Submit asChild disablePassingNonNativeProps>
                        <Button className={styles.form_action_submit}>Log in</Button>
                    </Form.Actions.Submit>
                </Form.Actions>
            </Form>
        </FormProvider>
    );
};

export { LoginPageForm };
