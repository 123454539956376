import React from "react";
import { createRoot } from "react-dom/client";

import { App } from "@/App";

// eslint-disable-next-line no-console
console.log(`LIOBS FE v${APP_VERSION} on ${import.meta.env.MODE} environment`);

createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
