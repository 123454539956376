import { configureStore } from "@reduxjs/toolkit";

import { authApi } from "@/store/services/auth.service";
import { rootApi } from "@/store/services/root-api";
import { authReducer } from "@/store/slices/auth.slice";
import { userReducer } from "@/store/slices/user.slice";

/**
 * Create and configure redux store using the `configureStore` (more info: https://redux.js.org/usage/configuring-your-store)
 */
export const store = configureStore({
    reducer: {
        [authApi.reducerPath]: authApi.reducer,
        [rootApi.reducerPath]: rootApi.reducer,
        auth: authReducer,
        user: userReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authApi.middleware, rootApi.middleware), // explicitly use `getDefaultMiddleware()`
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers(), // explicitly use `getDefaultEnhancers()`
});

/**
 * Infer the `RootState` type from the store
 */
export type RootState = ReturnType<typeof store.getState>;
/**
 * Infer the `AppDispatch` type from the store
 */
export type AppDispatch = typeof store.dispatch;
