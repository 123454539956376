import { Sidebar as WdsSidebar } from "@datamole/wds-component-sidebar";
import { NavLink } from "react-router-dom";

import { SidebarLinkItem } from "@/components/Layout/Sidebar/sidebar-items";

export const NonCollapsibleSidebarItem = (props: SidebarLinkItem) => {
    const { to, icon, name } = props;

    return (
        <WdsSidebar.Item asChild>
            <NavLink to={to}>
                {icon && <WdsSidebar.Item.Icon>{icon}</WdsSidebar.Item.Icon>}
                <WdsSidebar.Item.Content>{name}</WdsSidebar.Item.Content>
            </NavLink>
        </WdsSidebar.Item>
    );
};
