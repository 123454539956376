import { Heading } from "@datamole/wds-component-heading";
import type { ReactNode } from "react";

import styles from "@/components/Layout/Page/Header/PageHeader.module.css";

type Props = {
    children: ReactNode;
};

export const PageHeader = (props: Props) => {
    const { children } = props;

    return (
        <Heading as="h1" variant="h1" className={styles.page_header}>
            {children}
        </Heading>
    );
};
