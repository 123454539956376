import { Typography, TypographyProps } from "@datamole/wds-component-typography";

type Props = TypographyProps<"span">;

export const PageSectionHeader = ({ children }: Props) => {
    return (
        <Typography as="h2" colorShade="lighter" variant="lg" weight="medium">
            {children}
        </Typography>
    );
};
