import { Sidebar as WdsSidebar } from "@datamole/wds-component-sidebar";

import { CollapsibleSidebarItem } from "@/components/Layout/Sidebar/CollapsibleSidebarItem";
import { Header } from "@/components/Layout/Sidebar/Header/Header";
import { NonCollapsibleSidebarItem } from "@/components/Layout/Sidebar/NonCollapsibleSidebarItem";
import { sidebarItems } from "@/components/Layout/Sidebar/sidebar-items";

type SidebarProps = {
    className: string;
};

export const Sidebar = ({ className }: SidebarProps) => {
    return (
        <WdsSidebar className={className}>
            <WdsSidebar.Scrollable>
                <WdsSidebar.Content>
                    <Header />

                    <NonCollapsibleSidebarItem {...sidebarItems.dashboard} />
                    <CollapsibleSidebarItem {...sidebarItems.software} />
                    <CollapsibleSidebarItem {...sidebarItems.hardware} />
                </WdsSidebar.Content>
            </WdsSidebar.Scrollable>
        </WdsSidebar>
    );
};
