import { Sidebar as WdsSidebar, useCollapsible } from "@datamole/wds-component-sidebar";

import { NonCollapsibleSidebarItem } from "@/components/Layout/Sidebar/NonCollapsibleSidebarItem";
import { SidebarCollapsibleToggleItem } from "@/components/Layout/Sidebar/sidebar-items";

export const CollapsibleSidebarItem = (props: SidebarCollapsibleToggleItem) => {
    const { icon, name, subitems } = props;
    const { createCollapsibleProps, createCollapsibleToggleProps, isCollapsibleExpanded } = useCollapsible();

    return (
        <>
            <WdsSidebar.Item {...createCollapsibleToggleProps()}>
                {icon && <WdsSidebar.Item.Icon>{icon}</WdsSidebar.Item.Icon>}
                <WdsSidebar.Item.Content>{name}</WdsSidebar.Item.Content>
                <WdsSidebar.Collapsible.Indicator isCollapsibleExpanded={isCollapsibleExpanded} />
            </WdsSidebar.Item>

            <WdsSidebar.Collapsible {...createCollapsibleProps()}>
                {subitems.map((item) => (
                    <NonCollapsibleSidebarItem {...item} key={item.name} />
                ))}
            </WdsSidebar.Collapsible>
        </>
    );
};
