import { BaseQueryFn, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { config } from "@/config";
import { type RootState } from "@/store";
import { logout } from "@/store/slices/auth.slice";

const baseQuery = fetchBaseQuery({
    baseUrl: config.API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
        const accessToken = (getState() as RootState).auth.accessToken;
        if (accessToken) {
            headers.set("Authorization", `Bearer ${accessToken}`);
        }

        return headers;
    },
});

const baseQueryWithReauth: BaseQueryFn = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    // Handle token expiration or unauthorized response (401)
    if (result.error && result.error.status === 401) {
        // Optionally, handle logout/clear token or refresh the token
        api.dispatch(logout());
        // Redirect to login, show message, etc.
    }

    return result;
};

export const rootApi = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({}),
});
