import { Suspense } from "react";
import { Outlet } from "react-router-dom";

import styles from "@/components/Layout/Layout.module.css";
import { Navbar } from "@/components/Layout/Navbar";
import { Page } from "@/components/Layout/Page";
import { Sidebar } from "@/components/Layout/Sidebar";

export const Layout = () => {
    return (
        <div className={styles.layout}>
            <Sidebar className={styles.sidebar} />
            <Navbar className={styles.navbar} />
            <div className={styles.page_wrapper}>
                <Page className={styles.page}>
                    {/* Used because app pages are lazy loaded */}
                    <Suspense>
                        <Outlet />
                    </Suspense>
                </Page>
            </div>
        </div>
    );
};
