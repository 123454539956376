import { HTMLAttributes, ReactNode } from "react";

import styles from "@/components/Layout/Page/Section/PageSection.module.css";

type Props = HTMLAttributes<HTMLElement> & {
    children: ReactNode;
};

export const PageSection = ({ children }: Props) => {
    return <div className={styles.page_section}>{children}</div>;
};
