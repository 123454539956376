import { FormControl } from "@datamole/wds-component-form-control";
import { Input } from "@datamole/wds-component-input";
import { useFormContext } from "react-hook-form";

import styles from "@/pages/LoginPage/LoginPageForm.module.css";
import { LoginPageFormSchema } from "@/pages/LoginPage/login-page-from.schema";

type Props = {
    name: keyof LoginPageFormSchema;
    type: "text" | "password";
    label: string;
};

const LoginPageFormField = (props: Props) => {
    const { name, type, label } = props;

    const { register, getFieldState, formState } = useFormContext<LoginPageFormSchema>();
    const { error } = getFieldState(name, formState);

    return (
        <FormControl>
            <FormControl.Label htmlFor={name} className={styles.form_control_label}>
                {label}
            </FormControl.Label>
            <Input id={name} {...register(name)} type={type} className={styles.form_control_input} />
            <FormControl.ErrorMessage className={styles.form_control_error_message}>
                {error?.message}
            </FormControl.ErrorMessage>
        </FormControl>
    );
};

export { LoginPageFormField };
