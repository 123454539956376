import { Alert } from "@datamole/wds-component-alert";

type Props = {
    message: string;
};

const LoginPageFormError = (props: Props) => {
    const { message } = props;

    return (
        <Alert severity="error">
            <Alert.Icon />
            <Alert.Content>
                <Alert.Title>Authentication error</Alert.Title>
                {message}
            </Alert.Content>
        </Alert>
    );
};

export { LoginPageFormError };
