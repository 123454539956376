import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/**
 * State example - just a simple object with a string property for `name`
 */
type UserState = {
    name: string;
};

/**
 * Define a slice for the UserState (https://redux-toolkit.js.org/tutorials/quick-start#create-a-redux-state-slice)
 *     - use the name "user" to identify this slice
 *     - set an empty object as initial state
 *     - add `setUser` to the reducers
 */
const userSlice = createSlice({
    name: "user",
    initialState: {} as UserState,
    reducers: {
        setUser: (state, action: PayloadAction<UserState>) => {
            state.name = action.payload.name;
        },
    },
});

/**
 * Export setUser action generated by createSlice
 */
export const { setUser } = userSlice.actions;
/**
 * Export reducer generated by createSlice
 */
export const userReducer = userSlice.reducer;
