import styles from "@/pages/LoginPage/LoginpageLogo.module.css";
import lelyLogoImage from "@/pages/LoginPage/assets/lely-logo.png";

const LoginPageLogo = () => {
    return (
        <div className={styles.logo_container}>
            <img src={lelyLogoImage} alt={"logo"} className={styles.logo_image} />
        </div>
    );
};

export { LoginPageLogo };
