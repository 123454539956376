import { Routes, Route, Navigate } from "react-router-dom";

import { Layout } from "@/components/Layout";
import { pageAtRouteMap } from "@/pages";
import { LoginPage } from "@/pages/LoginPage";
import PageNotFoundPage from "@/pages/PageNotFoundPage";
import { routeNames, routes } from "@/routes";
import { ProtectedRoute } from "@/routes/ProtectedRoute";

/**
 * Create an array of objects with path and the component properties
 */
const pages = routeNames.map((routeName) => {
    const Component = pageAtRouteMap[routeName];
    return { path: routes[routeName].path, Component };
});

export const AppRoutes = () => (
    <Routes>
        <Route path={"/login"} element={<LoginPage />} />
        <Route element={<ProtectedRoute />}>
            <Route element={<Layout />}>
                <Route index element={<Navigate to={routes.dashboard.path} replace />} />
                {pages.map(({ path, Component }) => (
                    <Route key={path} path={path} element={<Component />} />
                ))}
                <Route key="404" path="*" element={<PageNotFoundPage />} />
            </Route>
        </Route>
    </Routes>
);
