import { Navigate } from "react-router-dom";

import styles from "@/pages/LoginPage/LoginPage.module.css";
import { LoginPageForm } from "@/pages/LoginPage/LoginPageForm";
import { LoginPageLogo } from "@/pages/LoginPage/LoginPageLogo";
import { LoginPageTitle } from "@/pages/LoginPage/LoginPageTitle";
import { useAppSelector } from "@/store";

const LoginPage = () => {
    const accessToken = useAppSelector((state) => state.auth.accessToken);

    // User is already logged in
    if (accessToken) {
        return <Navigate to="/" />;
    }

    return (
        <div className={styles.page}>
            <div className={styles.page_content_container}>
                <div className={styles.page_content}>
                    <LoginPageLogo />
                    <LoginPageTitle />
                    <LoginPageForm />
                </div>
            </div>
            <div className={styles.page_triangle} />
        </div>
    );
};

export { LoginPage };
